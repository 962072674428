<template>
  <div class='flex flex-col lg:flex-row min-h-screen'>
    <div
      class='w-full lg:w-2/3 px-4 lg:px-16 py-4 lg:py-16 hidden lg:flex flex-col items-center justify-center flex-shrink-0 flex-grow-0'
      style='background-image: url("https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_login_bg.png");background-repeat: no-repeat; background-position: 0 0;background-size: cover;'>
      <div class='flex flex-col justify-between items-center'>
        <div>
          <div class='max-w-lg text-center'>
            <img
              src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_wordmark_white.png'
              class='inline-block h-16'>
            <h1 class='text-3xl font-bold my-6' style='color: #F29B8F;'>The 14<span class='text-lg'
                style=' vertical-align: super'>th</span> Annual Scientific Session of the Korea Heart Rhythm Society
            </h1>
            <h2 class='text-xl text-white font-semibold leading-5'>June 24(Fri) - 25(Sat), 2022</h2>
          </div>
          <img
            src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image_white.png'
            class='mt-16 mx-auto' style='height: 40%; max-height: 400px;'>
        </div>
        <img
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_society_logo_white.png'
          class='h-10 flex-grow-0 flex-shrink-0'>
      </div>
    </div>
    <div
      class='w-full lg:w-1/3 py-4 lg:py-24 min-h-screen flex flex-col items-center justify-center px-4 lg:px-8 flex-shrink-0 flex-grow-0 bg-gray-100'>
      <login-form 
        :button-css-class-override='loginButtonCss' 
        :hide-password-field='hidePasswordField' 
        :hide-field-entry-confirmation='true' 
        :password-field-default-value='passwordFieldDefaultValue' 
        :single-session='true'
        logo-height='4rem'
        logo-custom-css='margin-left: auto; margin-right: auto;'
        class='mx-auto w-full lg:w-full max-w-xl py-16 rounded-md'>
        <template v-slot:bottom-content>
          <div class='flex flex-row gap-x-2 justify-center items-stretch mt-8'>
            <div
              class='flex-shrink-0 flex-grow-0 text-center bg-gray-200 py-4 px-6 rounded-md text-sm row-centered justify-center cursor-pointer'
              @click='openSiteGuide'
              style='width: 49%'>Site Guide<br>(in English)</div>
            <div
              class='flex-shrink-0 flex-grow-0 text-center bg-gray-200 py-4 px-6 rounded-md text-sm row-centered justify-center cursor-pointer'
              style='width: 49%' @click='openTextVideoModal'>Test Video</div>
          </div>
          <div class='text-gray-600 text-sm mt-8 px-4'>
            <h5 class='mt-4 text-center'><span :style='themeTextColorStyle'>*</span>For a comfortable experience with
              KHRS, <span class='xl:whitespace-nowrap'>please use the Google Chrome browser.</span></h5>
            <h5 class='mt-2 text-center'><span :style='themeTextColorStyle'>*</span>If you have any questions about your
              registed information, please contact us at:</h5>
            <div class='flex flex-col xl:flex-row items-center justify-center mt-4 px-4 gap-y-2 lg:gap-y-0'>
              <a class='block px-2 w-auto flex-shrink-0 flex-grow-0 text-center'
                href='mailto:khrs-reg@intercom.co.kr'>khrs-reg@intercom.co.kr</a>
              <div class='w-0.5 bg-gray-100 hidden xl:block text-gray-200 text-lg px-4'>|</div>
              <a class='block px-2 w-auto flex-shrink-0 flex-grow-0 text-center'>+82-2-564-4367</a>
            </div>
            <img class='mt-4'
              src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/sponsor_login_screen.png' />
          </div>
        </template>
      </login-form>
    </div>
    <modal name='test-video-modal' :adaptive='true' classes='test-video-modal relative'>
      <button class='z-30 absolute top-3 right-3' @click="$modal.hide('test-video-modal')">
        <x-icon class='text-black' />
      </button>
      <div class='mt-12' style='position:relative; padding-bottom:56.25%; overflow:hidden;'>
        <iframe
          width='100%'
          height='100%'
          src='https://play.wecandeo.com/video/v/?key=BOKNS9AQWrHp9hyeVcdM1Iy4Wg4l2jdgTPBXQ1ipu8Xhmmuzbipv1KyrKGKgfKFPwplFGipd7WMv3b27rE983vAVwieie'
          frameborder='0'
          allowfullscreen='' 
          allow='autoplay;fullscreen;'
          style='position:absolute;'>    
        </iframe>
      </div>
      <div class='text-gray-600 text-sm p-8'>
        <h5 class='mt-4 text-center'><span :style='themeTextColorStyle'>*</span>For a comfortable experience with
          KHRS, <span class='xl:whitespace-nowrap'>please use the Google Chrome browser.</span></h5>
        <h5 class='mt-2 text-center'><span :style='themeTextColorStyle'>*</span>If you have any questions about your
          registed information, please contact us at:</h5>
        <div class='flex flex-col xl:flex-row items-center justify-center mt-4 px-4 gap-y-2 lg:gap-y-0'>
          <a class='block px-2 w-auto flex-shrink-0 flex-grow-0 text-center'
            href='mailto:khrs-reg@intercom.co.kr'>khrs-reg@intercom.co.kr</a>
          <div class='w-0.5 hidden xl:block text-gray-200 text-lg px-4'>|</div>
          <a class='block px-2 w-auto flex-shrink-0 flex-grow-0 text-center'>+82-2-564-4367</a>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { XIcon }      from '@vue-hero-icons/outline'
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Login',
  components: {
    LoginForm,
    XIcon,
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
      'showingEventLoginPageConfigurations',
    ]),
    loginButtonCss () {
      return 'w-full p-4 rounded-md text-white'
    },
    passwordFieldDefaultValue () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordFieldDefaultValue : ''
    },
    hidePasswordField () {
      return this.showingEventLoginPageConfigurations && this.showingEventLoginPageConfigurations.hidePasswordField 
    },
  },
  methods: {
    openTextVideoModal () {
      this.$modal.show('test-video-modal')
    },
    openSiteGuide () {
      window.open('https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/Edited-KHRS+2022_Virtual+Site+Guideline_0620.pdf', '_blank')
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
.login-top-image {
  @apply mx-auto;
  width: 80%;
}

.test-video-modal {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 1024px) { 
  .test-video-modal {
    left: 25% !important;
    top: 10vh !important;
    height: auto !important;
    width: 50% !important;
  }

  .login-top-image {
    @apply mx-0;
    width: 32rem;
  }
}
</style>